<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown variant="link" no-caret toggle-class="p-0" right>
        <template #button-content>
          <b-avatar
            :src="userData.avatar"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          >
            <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
          </b-avatar>
        </template>

        <b-dropdown-item disabled>
          <span>{{ userData.nickName || userData.email }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          :to="{ name: 'profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>个人设置</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>退出登陆</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BAvatar,
  },
  data() {
    const userData1 = localStorage.getItem('userData')
    let userData12
    if (userData1 !== undefined) {
      userData12 = JSON.parse(userData1)
    }
    return {
      userData: userData12,
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
};
</script>
<style>
  [dir] .breadcrumb {
    padding: 0.5rem 1rem;
  }
</style>
