export default [
  {
    title: '企业管理',
    icon: 'icon-qiyeguanli',
    action: 'manage',
    children: [
      {
        title: '企业资源',
        icon: 'UsersIcon',
        route: 'resource',
        resource: 'resource',
        action: 'manage',
      },
      {
        title: 'SASS用户管理',
        icon: 'UsersIcon',
        route: 'sassenterprise',
        resource: 'sassenterprise',
        action: 'manage',
      },
      {
        title: '独立部署管理',
        icon: 'UsersIcon',
        route: 'enterprises',
        resource: 'enterprises',
        action: 'manage',
      },
      {
        title: '试用申请管理',
        icon: 'UsersIcon',
        route: 'trialenterprise',
        resource: 'trialenterprise',
        action: 'manage',
      },
      {
        title: '意见反馈管理',
        icon: 'UsersIcon',
        route: 'feedbackenterprise',
        resource: 'feedbackenterprise',
        action: 'manage',
      },
    ],
  },
  {
    title: '模板管理',
    icon: 'icon-mobanguanli',
    route: 'template',
    resource: 'template',
    action: 'manage',
    children: [
      {
        title: '隐私政策',
        icon: 'BookIcon',
        route: 'template-policy',
        resource: 'template-policy',
        action: 'manage',
      },
      {
        title: '隐私声明',
        icon: 'BookIcon',
        route: 'template-statement',
        resource: 'template-statement',
        action: 'manage',
      },
      {
        title: '制度流程',
        icon: 'BookIcon',
        route: 'regime',
        resource: 'template-regulations',
        action: 'manage',
      },
      {
        title: '合作协议',
        icon: 'BookIcon',
        route: 'template-collect',
        resource: 'template-collect',
        action: 'manage',
      },
      {
        title: '隐私组织',
        icon: 'BookIcon',
        route: 'org',
        resource: 'org',
        action: 'manage',
      },
    ],
  },
  {
    title: '评估管理',
    icon: 'icon-yedaipinggu',
    action: 'manage',
    children: [
      {
        title: '风险管理',
        icon: 'BookIcon',
        route: 'risk',
        resource: 'risk',
        action: 'manage',
      },
      {
        title: '风险类别管理',
        icon: 'BookIcon',
        route: 'riskCategory',
        resource: 'riskCategory',
        action: 'manage',
      },
      {
        title: '问卷管理',
        icon: 'BookIcon',
        route: 'questionTemplate',
        resource: 'questionTemplate',
        action: 'manage',
      },
      {
        title: '平台支撑管理',
        icon: 'BookIcon',
        route: 'support',
        resource: 'support',
        action: 'manage',
      },
    ],
  },
  {
    title: '字典库',
    icon: 'icon-zidianku',
    action: 'manage',
    children: [
      {
        title: '字典列表',
        icon: 'BookIcon',
        route: 'dict-library',
        resource: 'dict-library',
        action: 'manage',
      },
      {
        title: '字典标签',
        icon: 'BookIcon',
        route: 'dict-label',
        resource: 'dict-label',
        action: 'manage',
      },
/*       {
        title: '标签管理',
        icon: 'BookIcon',
        route: 'field-label',
        resource: 'field-label',
        action: 'manage',
      }, */
    ],
  },
  {
    title: '官网管理',
    icon: 'icon-guanwangguanli',
    action: 'manage',
    children: [
      {
        title: '行业洞察管理',
        icon: 'BookIcon',
        route: 'industry',
        resource: 'industry',
        action: 'manage',
      },
      {
        title: '法规要闻管理',
        icon: 'BookIcon',
        route: 'lawnews',
        resource: 'lawnews',
        action: 'manage',
      },
    ],
  },
  {
    title: '知识库管理',
    icon: 'icon-zhishiku',
    route: 'questionnaire',
    resource: 'questionnaire',
    action: 'manage',
    children: [
      {
        title: '法律库',
        icon: 'BookIcon',
        route: 'laws',
        resource: 'laws',
        action: 'manage',
      },
      {
        title: '案例库',
        icon: 'BookIcon',
        route: 'lawCase',
        resource: 'lawCase',
        action: 'manage',
      },
      {
        title: '法律类型',
        icon: 'BookIcon',
        route: 'lawType',
        resource: 'lawType',
        action: 'manage',
      },
      {
        title: '法律解读',
        icon: 'BookIcon',
        route: 'lawUnscramble',
        resource: 'lawUnscramble',
        action: 'manage',
      },
      // {
      //   title: '最佳实践',
      //   icon: 'BookIcon',
      //   route: 'questionnaire-template',
      //   menu: 'questionnaire-template',
      //   action: 'manage',
      // },
      {
        title: '最佳实践',
        icon: 'BookIcon',
        route: 'bestPractice',
        resource: 'bestPractice',
        action: 'manage',
      },
      // {
      //   title: '题库管理',
      //   icon: 'BookIcon',
      //   route: 'questionnaire-question',
      //   menu: 'questionnaire-question',
      //   action: 'manage',
      // },

    ],
  },
  {
    title: '系统配置',
    icon: 'icon-xitongshezhi',
    children: [
      {
        title: '字典管理',
        icon: 'DatabaseIcon',
        route: 'dictionaries',
        resource: 'dictionaries',
        action: 'manage',
      },
      {
        title: '菜单管理',
        icon: 'DatabaseIcon',
        route: 'menu',
        resource: 'menu',
        action: 'manage',
      },
      {
        title: '用户管理',
        icon: 'UsersIcon',
        route: 'user',
        resource: 'user',
        action: 'manage',
      },
      {
        title: '部门管理',
        icon: 'UsersIcon',
        route: 'organization',
        resource: 'organization',
        action: 'manage',
      },
      {
        title: '角色管理',
        icon: 'UsersIcon',
        route: 'role',
        resource: 'role',
        action: 'manage',
      },
      {
        title: '通知管理',
        route: 'notify',
        resource: 'notify',
        action: 'manage',
      },
      {
        title: '日志管理',
        route: 'log',
        resource: 'log',
        action: 'manage',
      },
      {
        title: '邮件设置',
        route: 'smtp',
        resource: 'email',
        action: 'manage',
      },
    ],
  },

]
